import { ContentCardData, CourseStatus } from '../cmsUtilities';

import sortBy from 'lodash/sortBy' ;

enum SubscriptionCategories {
  ESSENTIALS = 'Essentials',
  ENRICHMENT = 'Enrichment',
  ACADEMIC = 'Academic',
  ASSESSMENTS = 'Assessments'
}

const getCourseStatusValue = (s: CourseStatus | undefined) => {
  if (!s) {
    return -1;
  }
  else {
    switch (s) {
      case CourseStatus.COMPLETE:
        return 0;
        break;
      case CourseStatus.IN_PROGRESS:
        return 1;
        break;
      case CourseStatus.TO_DO:
        return 2;
        break;
      default:
        return -1;
        break;
    }
  }
};

const getSubscriptionCategoryValue = (s: Array<String>) => {
  if (!s || !s.length) {
    return -1;
  }
  else {
    if (s.find(v => v.includes(SubscriptionCategories.ESSENTIALS))) {
      return 3;
    }
    else if (s.find(v => v.includes(SubscriptionCategories.ENRICHMENT))) {
      return 2;
    }
    else if (s.find(v => v.includes(SubscriptionCategories.ACADEMIC))) {
      return 1;
    }
    else if (s.find(v => v.includes(SubscriptionCategories.ASSESSMENTS))) {
      return 0;
    }
    else {
      return -1;
    }
  }
};

const getCourseRankValue = (s: string) => {
  if (!s || !s.length) {
    return -1;
  }
  else {
    if (s.includes('1')) {
      return 3;
    }
    else if (s.includes('2')) {
      return 2;
    }
    else if (s.includes('3')) {
      return 1;
    }
    else {
      return -1;
    }
  }
};

const compareCourseStatus = (a: ContentCardData, b: ContentCardData) => {
  const aCourseStatus = a && a.courseStatus;
  const bCourseStatus = b && b.courseStatus;

  const aVal = getCourseStatusValue(aCourseStatus);
  const bVal = getCourseStatusValue(bCourseStatus);

  return bVal - aVal;
};

const compareSubCategory = (a: ContentCardData, b: ContentCardData) => {
  const aList = (a && a.subscriptionCategory) || [];
  const bList = (b && b.subscriptionCategory) || [];
  const aVal = getSubscriptionCategoryValue(aList);
  const bVal = getSubscriptionCategoryValue(bList);

  return bVal - aVal;
};

const compareSearchPriority = (a: ContentCardData, b: ContentCardData) => {
  const aList = (a && a.searchPriority) || '';
  const bList = (b && b.searchPriority) || '';
  const aVal = getCourseRankValue(aList);
  const bVal = getCourseRankValue(bList);

  return bVal - aVal;
};

export const sortCourseCards = (cards: Array<ContentCardData>) => {
  const result = sortBy(cards, [
    compareCourseStatus,
    compareSubCategory,
    compareSearchPriority
  ]);
  return result;
};
